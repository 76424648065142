import React, { memo } from "react"

import { useCore } from "../../../../../hooks/useCore"

export const withProductContentUsage = Component =>
  memo(({ name = "ProductContentUsage", colour, items: rawItems }: any) => {
    const {
      helpers: { sanityContent },
    } = useCore()

    const items = rawItems?.map(({ content, id, title }) => ({
      content: sanityContent(content),
      id,
      title,
    }))

    Component.displayName = name
    return <Component colour={colour} items={items} />
  })
