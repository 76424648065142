import React, { memo, useMemo } from "react"

import * as Components from "../../../../types/components"
import { PickMix } from "../../../../types/pickMix"
import slugify from "slugify"

export const withPickMixProgress = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "PickMixProgress", locales, step }: ExternalProps) => {
    const steps = useMemo(
      () => [
        {
          title: `${locales?.additionalStep?.toLocaleUpperCase()} 1`,
          subtitle: locales?.additionalStepOne,
          slug: slugify(locales?.additionalStepOne, "-"),
        },
        {
          slug: "line",
        },
        {
          title: `${locales?.additionalStep?.toLocaleUpperCase()} 2`,
          subtitle: locales?.additionalStepTwo,
          slug: slugify(locales?.additionalStepTwo, "-"),
        },
        {
          slug: "line",
        },
        {
          title: `${locales?.additionalStep?.toLocaleUpperCase()} 3`,
          subtitle: locales?.additionalStepThree,
          slug: slugify(locales?.additionalStepThree, "-"),
        },
      ],
      [locales?.additionalStep, locales?.additionalStepOne, locales?.additionalStepThree, locales?.additionalStepTwo]
    )

    const props = {
      steps,
      stepIndex: step * 2,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type Step = {
  title?: string
  subtitle?: string
  slug: string
}

type ExternalProps = Components.ComponentProps & {
  step: number
  locales: PickMix.Locales
}
type InjectedProps = {
  steps: Step[]
  stepIndex: number
}
