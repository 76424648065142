import React, { memo, useCallback, useEffect, useState } from "react"

import { useApp } from "../../../../hooks/useApp"
import { useTemplate } from "../../../../hooks/useTemplate"

export const withProductDrawer = Component =>
  memo(({ name = "ProductDrawer", bounds, activeProduct, active, template, handleClose, handleClickSelectProduct }: any) => {
    const [open, setOpen] = useState(null)
    const { globalState } = useApp()
    const { global: locales } = useTemplate()

    const [state] = globalState

    const handleSubnav = useCallback(
      (item: string) => {
        setOpen((prevState: string) => (prevState !== item ? item : null))
      },
      [setOpen]
    )

    useEffect(() => {
      if (open && !state?.activeMenu) {
        setOpen(null)
      }
    }, [open, setOpen, state])

    Component.displayName = name
    return activeProduct ? (
      <Component
        active={active}
        product={activeProduct}
        template={template}
        bounds={bounds}
        handleClose={handleClose}
        handleClickSelectProduct={handleClickSelectProduct}
        handleSubnav={handleSubnav}
        locales={locales}
        open={open}
      />
    ) : null
  })
