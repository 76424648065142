import tw, { css, styled } from "twin.macro"

import { GlobalStyles } from "../../../../Styled/Global"
import { StyledContainer } from "../../../../Styled/Container"
import { StyledColumn } from "../../../../Styled/Column"
import { StyledRow } from "../../../../Styled/Row"
import { StyledImage } from "../../../../Styled/Image"
import { H6, Overline, RichText } from "../../../../Styled/Text"

export const Section = tw.section`relative`
export const Content = styled.div`
  ${({ colour }) => colour && GlobalStyles.background[colour]}
`
export const Row = tw(StyledRow)`relative h-full`
export const Container = tw(StyledContainer)`h-full`
export const Title = tw(Overline)`text-xl tracking-normal max-w-full`
export const Text = styled(H6)`
  ${tw`mb-2 last:mb-0 max-w-full`}
  ${({ last }) => last && tw`mb-14`}
`
export const Image = tw(StyledImage)`w-20`
export const Senses = tw(StyledRow)`mb-6 last:mb-0`
export const Why = tw(RichText)`mb-12 last:mb-0 max-w-full`
export const Column = styled(StyledColumn)`
  ${({ colour }) => colour && GlobalStyles.background[colour]}
  ${({ withScroll }) => withScroll && tw`py-10.5`}
  ${({ withScroll }) =>
    withScroll &&
    css`
      &::-webkit-scrollbar {
        ${tw`w-1`}
      }
      &::-webkit-scrollbar-thumb {
        ${tw`bg-grey-light rounded-3xl`}
      }
    `}
`
