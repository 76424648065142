import React from "react"

import { withPickMixSelectHeader } from "./withPickMixSelectHeader"
import { Breadcrumbs } from "../../../Breadcrumbs/Breadcrumbs"

import { PlaceholderHero } from "./styledPickMixSelectHeader"

export const PickMixSelectHeader = withPickMixSelectHeader(
  ({ page }): JSX.Element => (
    <>
      <PlaceholderHero />
      <Breadcrumbs colour={"dark"} page={page} />
    </>
  )
)
