import React from "react"

import { withProductCard } from "./withProductCard"
import { StyledImage } from "../../../Styled/Image"
import { P, H5, RichText } from "../../../Styled/Text"
import {
  Card,
  ButtonLink,
  Button,
  Content,
  ImageContent,
  Image,
  ImageContainer,
  TextContent,
  TitleGroup,
  ProductContent,
  ButtonGroup,
  ButtonWrapper,
  ContentGroup,
  ContentBulletPoint,
} from "./styledProductCard"

export const ProductCard = withProductCard(
  ({
    colour,
    handleClickInfo,
    handleClickSelect,
    handleRemove,
    handleHover,
    hover,
    images,
    title,
    type,
    withSpacing,
    content,
    isBag,
    isReview,
    highlight,
    selectedMaxQty,
    isAvailable,
    locales,
  }) => (
    <Card
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      withSpacing={withSpacing}
      highlight={highlight ? "true" : undefined}
    >
      <ImageContent
        onClick={isReview || selectedMaxQty || !isAvailable ? undefined : handleClickSelect}
        clickable={!isReview && !selectedMaxQty && isAvailable ? "true" : undefined}
      >
        <ImageContainer colour={`${colour}-pastel`}>
          <Image hover={images[1] && hover}>
            <StyledImage alt={images[0]?.alt || title} fluid={{ ...images[0], aspectRatio: 4 / 3 }} />
          </Image>
          {images[1] && (
            <Image hover={!hover}>
              <StyledImage alt={images[1]?.alt || title} fluid={{ ...images[1], aspectRatio: 4 / 3 }} />
            </Image>
          )}
        </ImageContainer>
      </ImageContent>
      <Content>
        <TextContent>
          {title && (
            <TitleGroup>
              <H5 uppercase>{title}</H5>
              {type ? <P withSpacing={`xs`}>{type}</P> : <P dangerouslySetInnerHtml={{ __html: `&nbsp;` }} withSpacing={"md"} />}
            </TitleGroup>
          )}
          {content && (
            <ProductContent withSpacing={`xs`}>
              {typeof content === "string" ? (
                <ContentGroup>
                  <ContentBulletPoint withSpacing={`none`}>{"• "}</ContentBulletPoint>
                  <P withSpacing={`none`}>{content}</P>
                </ContentGroup>
              ) : Array.isArray(content) ? (
                content.map((text, index) => (
                  <ContentGroup key={index}>
                    <ContentBulletPoint withSpacing={`none`}>{"• "}</ContentBulletPoint>
                    <P withSpacing={`none`}>{text}</P>
                  </ContentGroup>
                ))
              ) : (
                <RichText withSpacing={`none`}>{content}</RichText>
              )}
            </ProductContent>
          )}
          <ProductContent withSpacing={`sm`}>
            {!isBag && !isReview && (
              <ButtonWrapper>
                <ButtonLink onClick={handleClickInfo} colour="orange-outline" size="tertiary-auto" full>
                  {locales?.additionalLearnMore}
                </ButtonLink>
              </ButtonWrapper>
            )}
          </ProductContent>
        </TextContent>
        <ButtonGroup>
          {isReview && (
            <ButtonWrapper>
              <Button onClick={handleRemove} colour="orange-outline" size="tertiary-auto" full>
                {locales?.additionalRemove}
              </Button>
            </ButtonWrapper>
          )}

          {!isReview && (
            <ButtonWrapper>
              <Button onClick={handleClickSelect} colour="orange" size="tertiary-auto" disabled={selectedMaxQty || !isAvailable} full>
                {!isAvailable ? locales?.additionalSoldOut : selectedMaxQty ? locales?.additionalSelected : locales?.additionalSelect}
              </Button>
            </ButtonWrapper>
          )}
        </ButtonGroup>
      </Content>
    </Card>
  )
)
