import React, { memo, useCallback, useMemo, useState } from "react"

import { useDom } from "../../../../../hooks/useDom"

export const withProductContentIngredients = Component =>
  memo(({ name = "ProductContentIngredients", colour, content: rawContent, ingredients, product, template }: any) => {
    const { dom } = useDom()
    const [active, setActive] = useState(null)
    const [open, setOpen] = useState(false)

    const image = product?.images?.[3] || product?.images?.[0]
    const ratio = dom?.isMedium ? 9 / 10 : 75 / 83
    const content = useMemo(
      () => ({
        ...rawContent,
        banned: template?.banned?.join(`, `),
        recipe: rawContent?.recipe?.map((item: any) => ({
          ...item,
          id: `recipe-${item?.id}`,
          icon: item?.icon?.toLowerCase()?.replace(/ /g, `-`) || null,
        })),
      }),
      [rawContent, template]
    )

    const handleItem = useCallback((event: any, id: string) => {
      event.stopPropagation()
      setActive((prevState: string) => (id === prevState ? null : id))
    }, [])
    const handleClick = useCallback(() => setOpen((prevState: boolean) => !prevState), [])

    Component.displayName = name
    return useMemo(
      () =>
        content?.recipe?.length > 0 || content?.ingredients?.length > 0 ? (
          <Component
            active={active}
            colour={colour}
            content={content}
            handleClick={handleClick}
            handleItem={handleItem}
            image={image}
            ingredients={ingredients}
            locales={template}
            open={open}
            ratio={ratio}
            title={product?.title}
          />
        ) : null,
      [active, colour, content, handleClick, handleItem, image, ingredients, template, open, product, ratio]
    )
  })
