import React, { memo, useCallback } from "react"

import * as Components from "../../../../types/components"
import { PickMix } from "../../../../types/pickMix"
import { useDom } from "../../../../hooks/useDom"

export const withPickMixSelectFilter = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "PickMixSelectFilter", items, selectedFilter, handleClick }: ExternalProps) => {
    const { dom } = useDom()

    const isMedium = dom?.isMedium
    const activeIndex = items?.map(item => item.productTag)?.indexOf(selectedFilter?.productTag) || 0

    const options = items.map(item => ({ label: item?.title, value: item?.productTag }))

    const handleChange = useCallback(
      ({ target: { value } }) => {
        handleClick(value)
      },
      [handleClick]
    )

    const props = {
      items,
      activeIndex,
      isMedium,
      options,
      handleClick,
      handleChange,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type Option = {
  label: string
  value: string
}

type ExternalProps = Components.ComponentProps & {
  items: PickMix.Item[]
  selectedFilter: PickMix.Item
  handleClick: (tag: string) => void
}
type InjectedProps = {
  items: PickMix.Item[]
  activeIndex: number
  isMedium: boolean
  options: Option[]
  handleClick: (handle: string) => void
  handleChange: (input: any) => void
}
