import React, { memo, useMemo } from "react"

export const withDetailImage = Component =>
  memo(({ name = "DetailImage", product }: any) => {
    const image = product?.images?.[2] || product?.images?.[0]
    const ratio = 75 / 83
    const title = product?.title

    Component.displayName = name
    return useMemo(() => <Component image={image} ratio={ratio} title={title} />, [image, ratio, title])
  })
