import React from "react"

import { withProductDrawer } from "./withProductDrawer"
import { DetailImage } from "./Image/DetailImage"
import { ProductContentUsage } from "./Usage/ProductContentUsage"
import { ProductContent } from "./Video/ProductContent"
import { ProductContentIngredients } from "./Ingredients/ProductContentIngredients"
import { ProductBar } from "./Bar/ProductBar"
import { Icon } from "../../../Icon/Icon"
import { StyledColumn } from "../../../Styled/Column"
import { StyledRow } from "../../../Styled/Row"
import { H3, P } from "../../../Styled/Text"
import {
  Close,
  Drawer,
  DrawerInner,
  Overlay,
  Section,
  Column,
  Content,
  Container,
  Row,
  Details,
  DescriptionRow,
  Description,
  DescriptionContent,
} from "./styledProductDrawer"

export const ProductDrawer = withProductDrawer(
  ({ active, product, template, bounds, handleClose, handleClickSelectProduct, locales }): JSX.Element => {
    return (
      <>
        <Overlay active={active} onClick={handleClose} />
        <Drawer active={active} top={bounds?.height}>
          <DrawerInner>
            <Close onClick={handleClose} title={locales?.additionalClose}>
              <Icon name={`close`} width={16} />
            </Close>
            <Section>
              <StyledRow items={`stretch`} withWrap>
                <Column colour={`${product?.colour}-pastel`} />
                <Column colour={product?.colour}>
                  <DetailImage product={product} />
                </Column>
              </StyledRow>
              <Content colour={`${product?.colour}-pastel`}>
                <Container width={`xl`}>
                  <Row items={`stretch`} withWrap>
                    <Column items={`start`} justify={`between`} vertical withScroll>
                      <Details>
                        <H3 as={`h1`} uppercase withSpacing={`xxs`}>
                          {product?.title}
                        </H3>
                        {product?.type && (
                          <P large withSpacing={`xs`}>
                            {product?.type}
                          </P>
                        )}
                        <DescriptionRow gutter={`sm`} items={`center`} withSpacing={`xs`}>
                          <StyledColumn gutter={`sm`} width={`no-shrink`}>
                            <Description colour={product?.colour} large>
                              {template?.additionalDescription}
                            </Description>
                          </StyledColumn>
                        </DescriptionRow>
                        <DescriptionContent colour={`grey-darker`}>{product?.description}</DescriptionContent>
                      </Details>
                    </Column>
                  </Row>
                </Container>
              </Content>
            </Section>
            <ProductContent colour={product?.colour} content={product?.content} template={template} />
            <ProductContentIngredients colour={product?.colour} content={product?.content} product={product} template={template} />
            <ProductContentUsage colour={product?.colour} items={product?.content?.usage} />
            <ProductBar active={true} product={product} handleClickSelectProduct={handleClickSelectProduct} handleClose={handleClose} />
          </DrawerInner>
        </Drawer>
      </>
    )
  }
)
