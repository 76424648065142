import React from "react"

import { withPickMixProgress } from "./withPickMixProgress"
import { Wrapper, Inner, StepWrapper, Step, Line, TextWrapper, Content } from "./styledPickMixProgress"

export const PickMixProgress = withPickMixProgress(
  ({ steps, stepIndex }): JSX.Element => (
    <Wrapper>
      <Inner>
        {steps.map((step, index) =>
          step.slug === "line" ? (
            <Line key={index} />
          ) : (
            <StepWrapper key={index}>
              <Step active={index === stepIndex ? "true" : undefined} />
              <TextWrapper>
                <Content active={index === stepIndex ? "true" : undefined}>{step.title}</Content>
                <Content active={index === stepIndex ? "true" : undefined}>{step.subtitle}</Content>
              </TextWrapper>
            </StepWrapper>
          )
        )}
      </Inner>
    </Wrapper>
  )
)
