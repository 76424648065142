import React from "react"

import { withPickMixSelect } from "./withPickMixSelect"
import { PickMixSelectHeader } from "./Header/PickMixSelectHeader"

import { PickMixProgress } from "./Progress/PickMixProgress"
import { PickMixSelectFilter } from "./Filter/PickMixSelectFilter"
import { PickMixSelectFooter } from "./Footer/PickMixSelectFooter"
import { ProductsSlides } from "./Slides/ProductsSlides"
import { ProductCard } from "./Card/ProductCard"
import { ProductDrawer } from "./Drawer/ProductDrawer"
import { StyledColumn } from "../../Styled/Column"
import { StyledRow } from "../../Styled/Row"
import { Inner, Container, ContentWrapper } from "./styledPickMixSelect"

export const PickMixSelect = withPickMixSelect(
  ({
    step,
    steps,
    locales,
    page,
    filters,
    selectedFilter,
    selectedProducts,
    activeProducts,
    activeProduct,
    activeDrawer,
    template,
    displaySlider,
    selectedProductCount,
    reviewId,
    handleNext,
    handleGoBack,
    handleClickFilter,
    handleCloseDrawer,
    handleClickProductInfo,
    handleSelectProduct,
    handleRemoveProduct,
  }): JSX.Element => (
    <>
      <PickMixSelectHeader locales={locales} page={page} />
      <ContentWrapper condensed={`md>`} width={`lg`}>
        <PickMixProgress locales={locales} step={step} />
        {step === steps.selectProducts && <PickMixSelectFilter items={filters} selectedFilter={selectedFilter} handleClick={handleClickFilter} />}
      </ContentWrapper>
      <Container width={`default`}>
        <Inner>
          <StyledRow gutter={`sm`} justify={step !== steps.reviewBag ? "center" : "start"} items={`stretch`} withWrap>
            {displaySlider ? (
              <ProductsSlides
                items={activeProducts}
                locales={locales}
                selectedProductCount={selectedProductCount}
                handleClickProductInfo={handleClickProductInfo}
                handleSelectProduct={handleSelectProduct}
              />
            ) : (
              activeProducts.map((product, index) => (
                <StyledColumn key={index} gutter={`sm`} items={`start`} justify={`end`} vertical width={`1/2 lg:1/4`}>
                  <ProductCard
                    product={product}
                    withSpacing={`lg`}
                    handleClickProductInfo={handleClickProductInfo}
                    handleClickSelectProduct={handleSelectProduct}
                    handleRemoveProduct={handleRemoveProduct}
                    selectedCount={selectedProductCount[product.id]}
                    isBag={step === steps.selectBag}
                    isReview={step === steps.reviewBag}
                    locales={locales}
                  />
                </StyledColumn>
              ))
            )}
          </StyledRow>
        </Inner>
      </Container>
      <PickMixSelectFooter
        selectedProducts={selectedProducts}
        handleRemoveProduct={handleRemoveProduct}
        handleNext={handleNext}
        handleGoBack={handleGoBack}
        locales={locales}
        step={step}
        steps={steps}
        reviewId={reviewId}
      />
      <ProductDrawer
        active={activeDrawer}
        activeProduct={activeProduct}
        template={template}
        handleClose={handleCloseDrawer}
        handleClickSelectProduct={handleSelectProduct}
      />
    </>
  )
)
