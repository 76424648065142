import React, { memo } from "react"

import * as Components from "../../../../types/components"
import { PickMix } from "../../../../types/pickMix"

import { useDom } from "../../../../hooks/useDom"

export const withProductsSlides = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "ProductsSlides", locales, items, selectedProductCount, handleClickProductInfo, handleSelectProduct }: ExternalProps) => {
    const {
      dom: { isMediumLarge, isExtraLarge },
    } = useDom()

    const props = {
      colour: "dark",
      items,
      selectedProductCount,
      handleClickProductInfo,
      handleSelectProduct,
      isMediumLarge,
      isExtraLarge,
      isBag: false,
      isReview: false,
      locales,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type ExternalProps = Components.ComponentProps & {
  items: PickMix.SelectableProduct[]
  locales: PickMix.Locales
  selectedProductCount: PickMix.SelectedProductCount
  handleClickProductInfo: (product: PickMix.SelectableProduct) => void
  handleSelectProduct: (product: PickMix.SelectableProduct) => void
}
type InjectedProps = {
  colour: string
  items: PickMix.SelectableProduct[]
  locales: PickMix.Locales
  selectedProductCount: PickMix.SelectedProductCount
  handleClickProductInfo: (product: PickMix.SelectableProduct) => void
  handleSelectProduct: (product: PickMix.SelectableProduct) => void
  isMediumLarge: boolean
  isExtraLarge: boolean
  isBag: boolean
  isReview: boolean
}
