import React, { memo, useCallback, useState } from "react"

import * as Components from "../../../../types/components"
import { GlobalStylesTypes } from "../../../Styled/Global"
import { PickMix } from "../../../../types/pickMix"
import { UseImage } from "../../../../types/hooks"
import { useImage } from "../../../../hooks/useImage"
import { useShopify } from "../../../../hooks/useShopify"

export const withProductCard = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(
    ({
      name = "ProductCard",
      product,
      withSpacing = null,
      isBag,
      isReview,
      selectedCount = 0,
      locales,
      handleClickProductInfo,
      handleClickSelectProduct,
      handleRemoveProduct,
    }: ExternalProps) => {
      const { getFluidImage, getStaticImage } = useImage()
      const { productNormaliser } = useShopify()
      const [hover, setHover] = useState(false)

      const item = { ...product, ...productNormaliser(product) }
      const images = [
        item.content?.coverImage ? getFluidImage(item.content.coverImage, { maxWidth: 1000 }) : getStaticImage(item?.images?.[0], { maxWidth: 1000 }),
        item?.images?.[1] ? getStaticImage(item?.images?.[1], { maxWidth: 1000 }) : null,
      ]?.filter(Boolean)

      const highlight = selectedCount > 0 && isBag
      const selectedMaxQty = isBag ? selectedCount > 0 : selectedCount === 2
      const content = item?.content?.bulletPoints?.length
        ? item.content.bulletPoints
        : item?.content?.suitability?.map((item: any) => `${item?.title}`)
      const isAvailable = item.variants?.[0].availableForSale

      const handleClickInfo = useCallback(() => {
        handleClickProductInfo(product)
      }, [handleClickProductInfo, product])

      const handleClickSelect = useCallback(() => {
        handleClickSelectProduct(product)
      }, [handleClickSelectProduct, product])

      const handleHover = useCallback((state: boolean) => setHover(state), [setHover])

      const handleRemove = useCallback(() => {
        if (handleRemoveProduct) {
          handleRemoveProduct(item.handle)
        }
      }, [handleRemoveProduct, item.handle])

      const props = {
        colour: item.colour,
        handleClickInfo,
        handleClickSelect,
        handleRemove,
        handleHover,
        hover,
        images,
        title: item.title,
        type: item.type,
        withSpacing,
        isBag,
        isReview,
        highlight,
        selectedMaxQty,
        content,
        isAvailable,
        locales,
      }

      Component.displayName = name
      return <Component {...(props as P)} />
    }
  )

type ExternalProps = Components.ComponentProps & {
  coverImage?: any
  product: PickMix.SelectableProduct
  withSpacing?: GlobalStylesTypes["spacing"]
  isBag?: boolean
  isReview?: boolean
  selectedCount: number
  locales: PickMix.Locales
  handleClickSelectProduct: (product: PickMix.SelectableProduct) => void
  handleClickProductInfo: (product: PickMix.SelectableProduct) => void
  handleRemoveProduct?: (productHandle: string) => void
}
type InjectedProps = {
  colour: string
  locales: PickMix.Locales
  handleClickInfo: () => void
  handleClickSelect: () => void
  handleRemove: () => void
  handleHover: (state: boolean) => void
  hover: boolean
  images: Array<UseImage.Image>
  title: string
  type: string
  withSpacing: GlobalStylesTypes["spacing"]
  content: any
  isBag: boolean
  isReview: boolean
  highlight: boolean
  selectedMaxQty: boolean
  isAvailable: boolean
}
