import tw, { styled } from "twin.macro"

import { GlobalStyles } from "../../../../Styled/Global"
import { StyledContainer } from "../../../../Styled/Container"
import { Overline, RichText } from "../../../../Styled/Text"

export const Item = styled.div`
  ${tw`py-12.5 border-b last:border-b-0`}
  ${({ colour }) => colour && GlobalStyles.border[colour]}
`
export const Container = tw(StyledContainer)``
export const Title = tw(Overline)`text-xl mb-4`
export const Content = tw(RichText)``
