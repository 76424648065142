import tw, { styled } from "twin.macro"

import { StyledContainer } from "../../../Styled/Container"
import { StyledButton, StyledLink } from "../../../Styled/Button"
import { StyledImage } from "../../../Styled/Image"
import { H4 } from "../../../Styled/Text"

export const Footer = tw.section`
  sticky bottom-0 inset-x-0 shadow-top py-4 md:py-5 h-20 md:h-30 z-10 bg-white visible transform translate-y-0 lg:translate-y-0 transition-all duration-500
`
export const Container = tw(StyledContainer)`h-full`
export const Inner = tw.div`flex w-full h-full items-center justify-between`
export const ImageGroup = tw.div`flex md:w-1/2 justify-end md:justify-start items-center`
export const ImageWrapper = styled.div`
  ${tw`relative w-10 h-10  md:w-15 md:h-15 lg:w-20 lg:h-20 z-1 mx-1 md:mx-1.5`}
`
export const Image = tw(StyledImage)`rounded-lg`
export const Placeholder = tw.div`flex h-full items-center text-orange text-3xl font-medium justify-center border border border-orange rounded-lg`
export const AspectRatio = tw.div`w-full h-full`
export const ButtonGroup = tw.div`flex items-center justify-end`
export const Button = tw(StyledButton)`min-w-25 md:min-w-55`
export const Link = styled(StyledLink)`
  ${({ center }) => (center ? tw`flex items-center mb-12 mx-auto` : "")}
`
export const LinkText = tw.span`ml-2`
export const TitleGroup = tw.div`flex md:ml-6`
export const Total = tw(H4)`text-lg md:text-4.25xl tracking-wide font-medium`
export const Amount = tw(H4)`pl-2 text-lg md:text-4.25xl tracking-wide text-black`
export const Close = styled(StyledLink)`
  ${tw`absolute top-0 right-0 -mt-1.5 -mr-1.5 p-1 z-30 bg-grey-lighter rounded-full`}

  svg {
    ${tw`w-3`}
  }
`
