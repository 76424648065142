import React from "react"

import { withProductBar } from "./withProductBar"
import { StyledContainer } from "../../../../Styled/Container"
import { Bar, Button } from "./styledProductBar"

export const ProductBar = withProductBar(({ active, bounds, handleClick, locales, product }) => (
  <Bar active={active} colour={`${product?.colour}-pastel`} top={bounds?.height}>
    <StyledContainer width={`xl`}>
      <Button full colour={`orange`} onClick={handleClick} size={`secondary`} title={locales?.additionalSelect}>
        {locales?.additionalSelect}
      </Button>
    </StyledContainer>
  </Bar>
))
