import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Mousewheel, Navigation } from "swiper"

import { withProductsSlides } from "./withProductSlides"
import { Icon } from "../../../Icon/Icon"
import { ProductCard } from "../Card/ProductCard"
import { StyledContainer } from "../../../Styled/Container"
import { Wrapper, Arrows, ArrowWrapper, Arrow } from "./styledProductsSlides"

SwiperCore.use([Autoplay, Mousewheel, Navigation])

export const ProductsSlides = withProductsSlides(
  ({
    colour,
    items,
    selectedProductCount,
    handleClickProductInfo,
    handleSelectProduct,
    isBag,
    isReview,
    isMediumLarge,
    isExtraLarge,
    locales,
  }): JSX.Element => (
    <>
      <Wrapper>
        <Swiper
          autoplay={false}
          lazy
          loop
          mousewheel={false}
          allowTouchMove={false}
          navigation={{ prevEl: "#pick-mix-left", nextEl: "#pick-mix-right" }}
          preloadImages={false}
          slidesPerView={isExtraLarge ? 4 : isMediumLarge ? 3 : 2}
          spaceBetween={20}
        >
          {items?.map((item, index) => (
            <SwiperSlide key={index}>
              <ProductCard
                product={item}
                selectedCount={selectedProductCount[item.id]}
                withSpacing={"default"}
                handleClickProductInfo={handleClickProductInfo}
                handleClickSelectProduct={handleSelectProduct}
                isBag={isBag}
                isReview={isReview}
                locales={locales}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Wrapper>
      <ArrowWrapper>
        <StyledContainer>
          <Arrows>
            <Arrow colour={colour} id={"pick-mix-left"} position={"left"} title={"left"}>
              <Icon name={"left"} />
            </Arrow>
            <Arrow colour={colour} id={"pick-mix-right"} position={"right"} title={"right"}>
              <Icon name={"right"} />
            </Arrow>
          </Arrows>
        </StyledContainer>
      </ArrowWrapper>
    </>
  )
)
