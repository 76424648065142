import React from "react"
import { graphql } from "gatsby"

import { PickMix as Page } from "../components/PickMix/PickMix"

export const query = graphql`
  query {
    page: sanityPagePickMix {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
    }
    pickMix: sanityPagePickMix {
      bags: _rawBags(resolveReferences: { maxDepth: 4 })
      products: _rawCollection(resolveReferences: { maxDepth: 4 })
      filter: _rawFilter(resolveReferences: { maxDepth: 4 })
      links: _rawLinks(resolveReferences: { maxDepth: 4 })
    }
    locales: sanityPagePickMix {
      additionalStart
      additionalNext
      additionalAddToCart
      additionalUpdateCart
      additionalLearnMore
      additionalRemove
      additionalSelect
      additionalSelectAlternative
      additionalSelected
      additionalSoldOut
      additionalStep
      additionalStepOne
      additionalStepTwo
      additionalStepThree
      additionalProductInfoHeadingOne
      additionalProductInfoHeadingTwo
      additionalProductInfoHeadingThree
      additionalTotal
      landingBannerTitle
      landingBannerSubtitle
      landingBannerImage: _rawLandingBannerImage(resolveReferences: { maxDepth: 2 })
      video: _rawVideo(resolveReferences: { maxDepth: 2 })
      landingBannerSubtitle
      landingBannerText
      landingBannerBackground
      headerBannerTitle
      headerBannerText
      headerBannerBackground
      totalPrice
    }
    products: allSanityProduct(filter: { shopify: { shopifyDeleted: { ne: true }, shopifyPublished: { eq: true } } }) {
      edges {
        node {
          id: _id
          tags
          title
          shopify {
            shopifyHandle
          }
          metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
          content {
            why: _rawWhy(resolveReferences: { maxDepth: 2 })
            ingredients {
              description
              id
              title
            }
            recipe {
              description
              icon
              id
              title
            }
            smells {
              id
              image: _rawImage(resolveReferences: { maxDepth: 2 })
              title
            }
            feels {
              id
              image: _rawImage(resolveReferences: { maxDepth: 2 })
              title
            }
            suitability {
              id
              title
            }
            usage {
              id: _key
              content: _rawContent(resolveReferences: { maxDepth: 2 })
              title
            }
            bulletPoints
            coverImage: _rawCoverImage(resolveReferences: { maxDepth: 4 })
            animationImage: _rawAnimationImage(resolveReferences: { maxDepth: 4 })
          }
        }
      }
    }
    template: sanityTemplateProduct {
      banned
      brand: _rawBrand(resolveReferences: { maxDepth: 2 })
      features
      recommendationsArrows
      recommendationsAutoplay
      recommendationsAutoplaySeconds
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      additionalFaqs
      additionalFaqsShort
      additionalIngredients
      additionalBanned
      additionalPause
      additionalPlay
      additionalFeels
      additionalSmells
      additionalSuitability
      additionalRelated
      additionalReviews
      additionalReviewsEmpty
      additionalReviewsName
      additionalReviewsEmail
      additionalReviewsLocation
      additionalReviewsTitle
      additionalReviewsContent
      additionalReviewsSubmit
      additionalReviewsSuccess
      additionalReviewsWrite
      additionalQuantityAdd
      additionalQuantityRemove
      additionalAddToCart
      additionalNotify
      additionalNotifyTitle
      additionalAfterpay
      additionalDescription
      additionalIngredientsButton
      additionalWhy
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => <Page {...props} {...data} />

export default Component
