import React from "react"

import { withPickMixSelectFooter } from "./withPickMixSelectFooter"
import { PickMixAddToCart } from "../AddToCart/PickMixAddToCart"
import { Icon } from "../../../Icon/Icon"
import { StyledColumn } from "../../../Styled/Column"
import {
  Footer,
  Close,
  Container,
  Inner,
  ImageGroup,
  ImageWrapper,
  Image,
  AspectRatio,
  ButtonGroup,
  Button,
  Link,
  LinkText,
  TitleGroup,
  Total,
  Amount,
  Placeholder,
} from "./styledPickMixSelectFooter"

export const PickMixSelectFooter = withPickMixSelectFooter(
  ({
    locales,
    selectedProducts,
    buttonDisabled,
    displayImages,
    displayNextButton,
    displayNextIcon,
    displayBackButton,
    displayAddToCartButton,
    placeholderItems,
    displayTotal,
    AddToCartTitle,
    isMedium,
    reviewId,
    handleRemoveProduct,
    handleClick,
    handleClickBack,
  }) => (
    <Footer>
      <Container width={`xl`}>
        <Inner>
          {!isMedium && displayBackButton && (
            <ButtonGroup>
              <StyledColumn gutter={`sm`} width={`no-shrink`}>
                <Link onClick={handleClickBack} title={"Back"}>
                  <Icon name={`left`} title={"Back"} width={12} />
                </Link>
              </StyledColumn>
            </ButtonGroup>
          )}
          <ImageGroup>
            {displayImages && (
              <>
                {selectedProducts.map((product, index) =>
                  product ? (
                    <ImageWrapper key={index} onClick={() => !isMedium && handleRemoveProduct(product?.handle)}>
                      {product?.images?.length ? <Image alt={product?.title} fluid={{ ...product?.images?.[0], aspectRatio: 1 }} /> : <AspectRatio />}
                      <Close onClick={() => isMedium && handleRemoveProduct(product?.handle)} title={"close"}>
                        <Icon name={`close`} width={4} />
                      </Close>
                    </ImageWrapper>
                  ) : (
                    <ImageWrapper key={index}>
                      <Placeholder>{index}</Placeholder>
                    </ImageWrapper>
                  )
                )}
                {placeholderItems.map(item => (
                  <ImageWrapper key={item}>
                    <Placeholder>{item}</Placeholder>
                  </ImageWrapper>
                ))}
              </>
            )}

            {displayTotal && (
              <TitleGroup>
                <Total>{locales?.additionalTotal}</Total>
                <Amount>{`$${locales?.totalPrice}`}</Amount>
              </TitleGroup>
            )}
          </ImageGroup>
          <ButtonGroup>
            {isMedium && displayBackButton && (
              <StyledColumn gutter={`sm`} width={`no-shrink`}>
                <Link onClick={handleClickBack} title={"Back"}>
                  <Icon name={`left`} title={"Back"} width={12} /> <LinkText>{"Back"}</LinkText>
                </Link>
              </StyledColumn>
            )}
            {displayNextButton && (
              <Button colour={`orange`} onClick={handleClick} size={`secondary`} title={locales?.additionalNext} disabled={buttonDisabled}>
                {locales?.additionalNext}
              </Button>
            )}
            {displayNextIcon && (
              <StyledColumn gutter={`sm`} width={`no-shrink`}>
                <Link onClick={handleClick} title={"Back"} disabled={buttonDisabled}>
                  <Icon name={`right`} title={"Back"} width={12} />
                </Link>
              </StyledColumn>
            )}
            {displayAddToCartButton && (
              <PickMixAddToCart
                reviewId={reviewId}
                selectedProducts={selectedProducts}
                buttonTitle={AddToCartTitle}
                buttonDisabled={buttonDisabled}
              />
            )}
          </ButtonGroup>
        </Inner>
      </Container>
    </Footer>
  )
)
