import tw from "twin.macro"
import styled from "@emotion/styled/macro"

import { P } from "../../../Styled/Text"

export const Wrapper = tw.div`md:mx-35 pt-10 px-10 pb-20`
export const Inner = tw.div`flex items-center`
export const StepWrapper = tw.div`flex items-center relative`
export const Step = styled.div<GenericProps>`
  ${tw`rounded-full transition duration-500 ease-in-out h-5 w-5 border-2 border-orange`}
  ${({ active }) => (active === "true" ? tw`bg-orange` : "")}
`
export const Line = tw.div`flex-auto border-t-2 transition duration-500 ease-in-out border-orange`

export const TextWrapper = tw.div`absolute top-0 -ml-13 text-center mt-8 w-32 font-medium`

export const Content = styled(P)<GenericProps>`
  ${tw`pt-3`}
  ${({ active }) => (active === "true" ? tw`font-bold` : "")}
`

type GenericProps = {
  active?: "true" | undefined
}
