import React, { memo, useMemo } from "react"

import * as Components from "../../../../types/components"
import * as PickMixTypes from "../../../../types/pickMix"

export const withPickMixSelectHeader = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "PickMixSelectHeader", locales, page }: ExternalProps) => {
    const banner = useMemo(
      () => ({
        background: locales?.headerBannerBackground,
        position: `bottom`,
        size: `large`,
        title: locales?.headerBannerTitle,
        text: locales?.headerBannerText?.toLowerCase(),
      }),
      [locales]
    )

    const props = {
      banner,
      page,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type ExternalProps = Components.ComponentProps & {
  locales: PickMixTypes.PickMix.Locales
  page: Components.PageProps
}
type InjectedProps = {
  banner: Banner
  page: Components.PageProps
  handleNext: () => void
}

type Banner = {
  background: string
  position?: string
  size?: string
  title: string
  text: string
}
