import React from "react"

import { withPickMix } from "./withPickMix"
import { PickMixLanding } from "./Landing/PickMixLanding"
import { PickMixSelect } from "./Select/PickMixSelect"

export const PickMix = withPickMix(
  ({ handleNextView, page, view, locales, pickMixBags, pickMixProducts, pickMixFilter, products, template }): JSX.Element => {
    switch (view) {
      case "Landing":
        return <PickMixLanding locales={locales} handleNext={() => handleNextView("Select")} page={page} />
      case "Select":
        return (
          <PickMixSelect
            locales={locales}
            page={page}
            pickMixBags={pickMixBags}
            pickMixProducts={pickMixProducts}
            pickMixFilter={pickMixFilter}
            products={products}
            template={template}
          />
        )
      default:
        return null
    }
  }
)
