import React, { memo, useMemo } from "react"

import { useCore } from "../../../../../hooks/useCore"
import { useImage } from "../../../../../hooks/useImage"

export const withProductContent = Component =>
  memo(({ name = "ProductContent", colour, content: rawContent, template }: any) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { getFluidImage } = useImage()

    const content = useMemo(
      () => ({
        ...rawContent,
        feels: rawContent?.feels?.map((item: any) => ({
          ...item,
          image: getFluidImage(item?.image, { maxWidth: 800 }),
        })),
        smells: rawContent?.smells?.map((item: any) => ({
          ...item,
          image: getFluidImage(item?.image, { maxWidth: 800 }),
        })),
        why: sanityContent(rawContent?.why),
      }),
      [getFluidImage, rawContent, sanityContent]
    )

    Component.displayName = name
    return useMemo(
      () =>
        content?.feels?.length > 0 || content?.smells?.length > 0 || content?.why ? (
          <Component colour={colour} content={content} locales={template} />
        ) : null,
      [colour, content, template]
    )
  })
