import React, { memo, useState, useCallback } from "react"

import * as Components from "../../types/components"
import * as Sanity from "../../types/sanity"
import { PickMix } from "../../types/pickMix"
import { useApp } from "../../hooks/useApp"
import { useRoutes } from "../../hooks/useRoutes"

export const withPickMix = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "PickMix", location, products, page, pickMix, locales, template }: ExternalProps) => {
    const {
      config: {
        settings: { params },
      },
    } = useApp()
    const { getUrlParameter } = useRoutes()

    const reviewId = getUrlParameter(params.review, location)

    const [view, setView] = useState<View>(reviewId ? "Select" : "Landing")

    const handleNextView = useCallback((nextView: View) => {
      setView(nextView)
    }, [])

    const props = {
      locales,
      view,
      page,
      handleNextView,
      pickMixBags: pickMix.bags,
      pickMixProducts: pickMix.products,
      pickMixFilter: pickMix.filter,
      products,
      template,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type View = "Landing" | "Select"

type InjectedProps = {
  page?: Components.PageProps
  view: View
  locales: PickMix.Locales
  handleNextView: (nextView: View) => void
  pickMixBags: Sanity.Collection
  pickMixProducts: Sanity.Collection
  pickMixFilter: PickMix.Item[]
  products: any
  template: any
}

type ExternalProps = Components.ComponentProps & {
  page?: Components.PageProps
  locales: PickMix.Locales
  products: any
  pickMix: {
    bags: Sanity.Collection
    products: Sanity.Collection
    filter: PickMix.Item[]
  }
  template: any
}
