import React, { memo, useMemo } from "react"

import * as Components from "../../../types/components"
import * as Sanity from "../../../types/sanity"
import * as PickMixTypes from "../../../types/pickMix"
import { useImage } from "../../../hooks/useImage"

export const withPickMixLanding = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "PickMixLanding", locales, page, handleNext }: ExternalProps) => {
    const { getResponsiveImage } = useImage()
    const banner = useMemo(
      () => ({
        background: locales?.landingBannerBackground,
        image: getResponsiveImage(locales?.landingBannerImage, { desktop: { maxWidth: 2000 }, mobile: { maxWidth: 800 } }),
        video: locales?.video,
        button: "orange",
        buttonSize: "secondary",
        position: "middle",
        size: "large",
        title: locales?.landingBannerTitle,
        link: { title: locales?.additionalStart },
        content: locales?.landingBannerSubtitle,
        text: locales?.landingBannerText?.toLowerCase(),
        dense: true,
      }),
      [
        getResponsiveImage,
        locales?.additionalStart,
        locales?.landingBannerBackground,
        locales?.landingBannerImage,
        locales?.landingBannerSubtitle,
        locales?.landingBannerText,
        locales?.landingBannerTitle,
        locales?.video,
      ]
    )

    const props = {
      banner,
      page,
      handleNext,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type ExternalProps = Components.ComponentProps & {
  locales: PickMixTypes.PickMix.Locales
  page: Components.PageProps
  handleNext: () => void
}
type InjectedProps = {
  banner: Banner
  page: Components.PageProps
  handleNext: () => void
}

type Banner = {
  background: string
  image: {
    desktop: any
    mobile: any
  }
  video: Sanity.VideoResponsive
  button: string
  buttonSize: string
  position: string
  size: string
  title: string
  link: { title: string }
  content: string
  text: string
  dense: boolean
}
