import React from "react"

import { withProductContent } from "./withProductContent"
import { StyledColumn } from "../../../../Styled/Column"
import { Section, Content, Column, Container, Title, Text, Image, Row, Senses, Why } from "./styledProductContent"

export const ProductContent = withProductContent(
  ({ colour, content, locales }): JSX.Element => (
    <Section>
      <Content colour={`${colour}-pastel`}>
        <Container width={`xl`}>
          <Row gutter={`xl`} items={`stretch`} withWrap>
            <Column gutter={`xl`} withScroll>
              {content?.suitability?.length > 0 && (
                <>
                  <Title colour={colour} large withSpacing={`xs`}>
                    {locales?.additionalSuitability}
                  </Title>
                  {content?.suitability?.map(({ id, title }, index: number) => (
                    <Text key={id?.toString()} as={`p`} colour={`grey-darker`} last={index === content?.suitability?.length - 1}>
                      {title}
                    </Text>
                  ))}
                </>
              )}
              {content?.why && (
                <>
                  <Title colour={colour} large withSpacing={`xs`}>
                    {locales?.additionalWhy}
                  </Title>
                  <Why colour={`grey-darker`}>{content?.why}</Why>
                </>
              )}
              {content?.smells?.length > 0 && (
                <>
                  {content?.smells?.map(({ id, image, title }) => (
                    <Senses key={id} gutter={`sm`} items={`center`}>
                      <StyledColumn gutter={`sm`} width={`no-shrink`}>
                        <Image alt={image?.alt || title} fluid={image} />
                      </StyledColumn>
                      <StyledColumn gutter={`sm`} width={`grow`}>
                        <Title colour={colour} large withSpacing={`xxxs`}>
                          {locales?.additionalSmells}
                        </Title>
                        <Text colour={`grey-darker`}>{title}</Text>
                      </StyledColumn>
                    </Senses>
                  ))}
                </>
              )}
              {content?.feels?.length > 0 && (
                <>
                  {content?.feels?.map(({ id, image, title }) => (
                    <Senses key={id} gutter={`sm`} items={`center`}>
                      <StyledColumn gutter={`sm`} width={`no-shrink`}>
                        <Image alt={image?.alt || title} fluid={image} />
                      </StyledColumn>
                      <StyledColumn gutter={`sm`} width={`grow`}>
                        <Title colour={colour} large withSpacing={`xxxs`}>
                          {locales?.additionalFeels}
                        </Title>
                        <Text colour={`grey-darker`}>{title}</Text>
                      </StyledColumn>
                    </Senses>
                  ))}
                </>
              )}
            </Column>
          </Row>
        </Container>
      </Content>
    </Section>
  )
)
