import React from "react"

import { withProductContentUsage } from "./withProductContentUsage"
import { StyledColumn } from "../../../../Styled/Column"
import { StyledRow } from "../../../../Styled/Row"
import { Item, Container, Content, Title } from "./styledProductContentUsage"

export const ProductContentUsage = withProductContentUsage(
  ({ colour, items }): JSX.Element => (
    <section>
      {items?.map(({ content, id, title }) => (
        <Item key={id?.toString()} colour={colour} gutter={`sm`} withWrap>
          <Container width={`xl`}>
            <StyledRow gutter={`xl`} items={`center`} withWrap>
              <StyledColumn gutter={`xl`}>
                <Title colour={colour}>{title}</Title>
              </StyledColumn>
              <StyledColumn gutter={`xl`}>
                <Content colour={`grey-darker`}>{content}</Content>
              </StyledColumn>
            </StyledRow>
          </Container>
        </Item>
      ))}
    </section>
  )
)
