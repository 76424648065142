import React, { memo, useCallback } from "react"
import uuid from "react-uuid"

import * as Components from "../../../../types/components"
import { PickMix } from "../../../../types/pickMix"
import { useApp } from "../../../../hooks/useApp"
import { useCore } from "../../../../hooks/useCore"
import { usePickMix } from "../../../../hooks/usePickMix"
import { useCart } from "../../../../hooks/useCart"
import { useImage } from "../../../../hooks/useImage"

export const withPickMixAddToCart = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "PickMixAddToCart", reviewId, buttonTitle, buttonDisabled, selectedProducts }: ExternalProps) => {
    const {
      config: {
        settings: { customAttributeKeys },
      },
      setActivePickMixPopup,
    } = useApp()
    const {
      helpers: { decodeShopifyId },
    } = useCore()
    const { addMultipleToCart } = useCart()
    const { handleAnimationPopup } = usePickMix()
    const { getFluidImage } = useImage()

    const handleAdd = useCallback(async () => {
      const selectedItems = [...selectedProducts]

      const images = selectedItems.map(product =>
        product?.content?.animationImage
          ? getFluidImage(product.content.animationImage, { maxWidth: 200 })
          : product.images?.length
          ? product.images[product.images.length - 1]
          : null
      )

      if (selectedItems.length < 5) {
        return
      }

      handleAnimationPopup(images)

      const bagItem = selectedItems.shift()
      const bagVariantId = bagItem.variants?.[0]?.id

      if (!bagVariantId) {
        return
      }

      const groupedVariantId = decodeShopifyId(bagVariantId, `Variant`)
      const groupedId = reviewId || uuid()

      const attributes = [
        {
          key: customAttributeKeys.grouped,
          value: groupedId,
        },
        {
          key: customAttributeKeys.groupedVariantId,
          value: groupedVariantId,
        },
      ]

      const groupedItems = selectedItems.reduce((result, item) => {
        const key = item.variants?.[0]?.id
        if (!key) {
          return result
        }

        if (result[key]) {
          result[key].quantity = result[key].quantity + 1
        } else {
          result[key] = {
            variantId: key,
            quantity: 1,
            groupedId,
            customAttributes: [
              ...attributes,
              {
                key: customAttributeKeys.groupedName,
                value: bagItem.title,
              },
            ],
          }
        }
        return result
      }, {})

      const input = Object.values(groupedItems) as unknown as Array<{
        variantId: string
        quantity: number
        customAttributes: Array<{ key: string; value: string }>
      }>
      input.unshift({ variantId: bagVariantId, quantity: 1, customAttributes: attributes })

      setTimeout(async () => {
        await addMultipleToCart(input, groupedId)
        setActivePickMixPopup(true)
      }, 2000)
    }, [
      addMultipleToCart,
      customAttributeKeys.grouped,
      customAttributeKeys.groupedName,
      customAttributeKeys.groupedVariantId,
      decodeShopifyId,
      getFluidImage,
      handleAnimationPopup,
      reviewId,
      selectedProducts,
      setActivePickMixPopup,
    ])

    const props = {
      buttonTitle,
      buttonDisabled,
      handleAdd,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type ExternalProps = Components.ComponentProps & {
  reviewId?: string
  buttonTitle: string
  buttonDisabled: boolean
  selectedProducts: PickMix.SelectableProduct[]
}
type InjectedProps = {
  buttonTitle: string
  handleAdd: () => Promise<void>
  buttonDisabled: boolean
}
