import React, { memo, useCallback, useMemo, useState, useEffect } from "react"

import * as Components from "../../../types/components"
import * as Sanity from "../../../types/sanity"
import { PickMix } from "../../../types/pickMix"
import { useApp } from "../../../hooks/useApp"
import { useDom } from "../../../hooks/useDom"
import { usePickMix } from "../../../hooks/usePickMix"
import { useShopify } from "../../../hooks/useShopify"
import { useRoutes } from "../../../hooks/useRoutes"
import { useCheckout } from "../../../hooks/useCheckout"

export const withPickMixSelect = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "PickMixSelect", location, locales, page, pickMixBags, pickMixProducts, pickMixFilter, products, template }: ExternalProps) => {
    const {
      config: {
        settings: { params, customAttributeKeys },
      },
      helpers: { edgeNormaliser },
    } = useApp()
    const { checkout } = useCheckout()
    const { useCollections } = useShopify()
    const { getSelectableProducts, mergeProducts, convertFilter } = usePickMix()
    const {
      dom: { isMedium, isMediumLarge, isExtraLarge },
    } = useDom()
    const { getUrlParameter } = useRoutes()

    const reviewId = getUrlParameter(params.review, location)
    const lineItems = checkout?.lineItems

    const selectedHandles =
      lineItems?.reduce((result, item) => {
        const groupedId = item?.customAttributes?.find(({ key }) => key === customAttributeKeys.grouped)?.value
        if (reviewId && groupedId && groupedId === reviewId) {
          if (result[item?.product?.handle]) {
            result[item?.product?.handle] = result[item?.product?.handle] + item.quantity
          } else {
            result[item?.product?.handle] = item.quantity
          }
        }
        return result
      }, {}) || {}

    const STEPS = useMemo(
      () => ({
        selectBag: 0,
        selectProducts: 1,
        reviewBag: 2,
      }),
      []
    )

    const filters = convertFilter(pickMixFilter)
    const allSanityProducts: Array<Sanity.Product> = edgeNormaliser(products)

    const bagProductByCollection = useCollections({
      firstImages: 10,
      firstProducts: 10,
      firstVariants: 1,
      handles: pickMixBags ? [pickMixBags?.shopify?.shopifyHandle] : null,
    })

    const productByCollection = useCollections({
      firstImages: 10,
      firstProducts: 50,
      firstVariants: 1,
      handles: pickMixProducts ? [pickMixProducts?.shopify?.shopifyHandle] : null,
    })

    const bags = useMemo(
      () => mergeProducts(bagProductByCollection?.[0]?.products, allSanityProducts),
      [allSanityProducts, bagProductByCollection, mergeProducts]
    )

    const selectableProducts = getSelectableProducts({
      filters: filters,
      shopifyProducts: productByCollection?.[0]?.products || [],
      sanityProducts: allSanityProducts,
    })

    const currentSelectedBag: PickMix.SelectableProduct = bags.find(bag => selectedHandles[bag.handle])

    const currentSelectedProducts: PickMix.SelectableProduct[] = selectableProducts["All products"].reduce((result, product) => {
      if (selectedHandles[product.handle]) {
        for (let i = 0; i < selectedHandles[product.handle]; i++) {
          result.push(product)
        }
      }
      return result
    }, [])

    if (currentSelectedBag) {
      currentSelectedProducts.unshift(currentSelectedBag)
    }

    const [step, setStep] = useState<number>(reviewId ? STEPS.reviewBag : STEPS.selectBag)
    const [selectedFilter, setSelectedFilter] = useState<PickMix.Item>(filters[0])
    const [selectedProducts, setSelectedProducts] = useState<Array<PickMix.SelectableProduct>>(
      currentSelectedProducts.length === 5 ? currentSelectedProducts : []
    )
    const [activeProduct, setActiveProduct] = useState<PickMix.SelectableProduct | null>(null)
    const [activeDrawer, setActiveDrawer] = useState<boolean>(false)

    const activeProducts = useMemo(() => {
      switch (step) {
        case STEPS.selectBag:
          return bags
        case STEPS.selectProducts:
          return selectableProducts[selectedFilter?.title || "All products"]
        case STEPS.reviewBag:
          return selectedProducts.slice(1, 5).filter(p => p)
        default:
          return bags
      }
    }, [STEPS, bags, selectableProducts, selectedFilter?.title, selectedProducts, step])

    const displaySlider = useMemo(() => {
      const length = activeProducts?.length || 0
      const needSlider =
        (isExtraLarge && length > 4) || (isMediumLarge && !isExtraLarge && length > 3) || (!isMediumLarge && !isExtraLarge && length > 2)

      return isMedium && needSlider && step !== STEPS.reviewBag
    }, [STEPS.reviewBag, activeProducts?.length, isExtraLarge, isMedium, isMediumLarge, step])

    const selectedProductCount = selectedProducts.reduce((result, item) => {
      if (item) {
        result[item.id] = result[item.id] ? result[item.id] + 1 : 1
      }

      return result
    }, {})

    const handleClickProductInfo = useCallback((product: PickMix.SelectableProduct) => {
      setActiveProduct(product)
    }, [])

    const handleCloseDrawer = useCallback(() => {
      setActiveDrawer(false)
    }, [])

    const handleClickFilter = useCallback(
      (tag: string) => {
        const find = filters.find(item => item.productTag === tag)
        setSelectedFilter(find ? find : null)
      },
      [filters]
    )

    const handleNext = useCallback(
      (index?: number) => {
        if (index !== undefined) {
          setStep(index)
        } else {
          setStep(prev => (prev < Object.keys(STEPS).length - 1 ? prev + 1 : prev))
        }
      },
      [STEPS]
    )

    const handleGoBack = useCallback((index?: number) => {
      if (index !== undefined) {
        setStep(index)
      } else {
        setStep(prev => (prev > 0 ? prev - 1 : prev))
      }
    }, [])

    const handleSelectProduct = useCallback(
      (product: PickMix.SelectableProduct) => {
        const currentProducts = [...selectedProducts]
        if (step === STEPS.selectBag) {
          currentProducts.shift()
          currentProducts.unshift(product)
          setSelectedProducts(currentProducts)
        } else {
          const bag = currentProducts.shift()
          const indexNull = currentProducts.indexOf(null)
          if (indexNull >= 0) {
            currentProducts.splice(indexNull, 1, product)
          } else if (currentProducts.length < 4) {
            currentProducts.push(product)
          }
          currentProducts.unshift(bag)
          setSelectedProducts(currentProducts)
        }

        if ((step === STEPS.selectBag || step === STEPS.selectProducts) && currentProducts.filter(p => p).length === 5) {
          handleNext(2)
        }
      },
      [STEPS.selectBag, STEPS.selectProducts, handleNext, selectedProducts, step]
    )

    const handleRemoveProduct = useCallback(
      (productHandle: string) => {
        const currentProducts = [...selectedProducts]
        const productHandles = currentProducts.map(product => product?.handle)
        const index = productHandles.indexOf(productHandle)
        currentProducts.splice(index, 1, null)
        const bagItem = currentProducts.shift()
        const filteredProducts = currentProducts.sort((a, b) => {
          // nulls sort after anything else
          if (a === null) {
            return 1
          } else if (b === null) {
            return -1
          } else {
            return 0
          }
        })
        filteredProducts.unshift(bagItem)
        setSelectedProducts(filteredProducts)
      },
      [selectedProducts]
    )

    useEffect(() => {
      if (!selectedProducts.length && currentSelectedProducts.length === 5) {
        setSelectedProducts(currentSelectedProducts)
      }
    }, [currentSelectedProducts, selectedProducts.length])

    useEffect(() => {
      if (activeProduct) {
        setActiveDrawer(true)
      }
    }, [activeProduct])

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }, [step])

    useEffect(() => {
      const currentProducts = selectedProducts.filter(product => product)
      if (!reviewId) {
        if (!selectedProducts?.[0]) {
          handleGoBack(0)
        } else if (step === STEPS.reviewBag && currentProducts.length < 5) {
          handleGoBack()
        }
      } else {
        if (!selectedProducts?.[0] && selectedProducts.length === 5) {
          handleGoBack(0)
        } else if (step === STEPS.reviewBag && selectedProducts.length === 5 && currentProducts.length < 5) {
          handleGoBack()
        }
      }
    }, [STEPS.reviewBag, handleGoBack, handleNext, isMedium, reviewId, selectedProducts, selectedProducts.length, step])

    const props = {
      step,
      steps: STEPS,
      locales,
      page,
      filters,
      selectedFilter,
      selectedProducts,
      bags,
      activeProducts,
      activeProduct,
      activeDrawer,
      template,
      displaySlider,
      selectedProductCount,
      reviewId: currentSelectedProducts?.length === 5 && reviewId,
      handleNext,
      handleGoBack,
      handleClickFilter,
      handleCloseDrawer,
      handleClickProductInfo,
      handleSelectProduct,
      handleRemoveProduct,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type ExternalProps = Components.ComponentProps & {
  locales: PickMix.Locales
  page: Components.PageProps
  pickMixBags: Sanity.Collection
  pickMixProducts: Sanity.Collection
  pickMixFilter: PickMix.Item[]
  products: any
  template: any
}
type InjectedProps = {
  step: number
  steps: PickMix.Steps
  locales: PickMix.Locales
  page: Components.PageProps
  filters: PickMix.Item[]
  selectedFilter: PickMix.Item
  selectedProducts: PickMix.SelectableProduct[]
  bags: PickMix.SelectableProduct[]
  activeProducts: PickMix.SelectableProduct[]
  activeProduct: PickMix.SelectableProduct
  activeDrawer: boolean
  template: any
  displaySlider: boolean
  selectedProductCount: PickMix.SelectedProductCount
  reviewId: string
  handleNext: () => void
  handleGoBack: () => void
  handleClickFilter: (handle: string) => void
  handleCloseDrawer: () => void
  handleClickProductInfo: (product: PickMix.SelectableProduct) => void
  handleSelectProduct: (product: PickMix.SelectableProduct) => void
  handleRemoveProduct: (productHandle: string) => void
}
