import React from "react"

import { withPickMixLanding } from "./withPickMixLanding"
import { Breadcrumbs } from "../../Breadcrumbs/Breadcrumbs"
import { HeroItem } from "../../Sections/Hero/Item/HeroItem"

export const PickMixLanding = withPickMixLanding(
  ({ banner, page, handleNext }): JSX.Element => (
    <>
      <HeroItem item={banner} handlePromoClick={handleNext} />
      <Breadcrumbs colour={banner?.text} page={page} />
    </>
  )
)
