import React, { memo, useCallback, useMemo } from "react"

import * as Components from "../../../../types/components"
import { PickMix } from "../../../../types/pickMix"
import { useDom } from "../../../../hooks/useDom"

export const withPickMixSelectFooter = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(
    ({
      name = "PickMixSelectFooter",
      step,
      steps,
      locales,
      selectedProducts,
      reviewId,
      handleRemoveProduct,
      handleNext,
      handleGoBack,
    }: ExternalProps) => {
      const {
        dom: { isSmall, isMedium, isExtraLarge },
      } = useDom()

      const buttonDisabled = useMemo(() => {
        const currentSelectedProducts = selectedProducts.filter(p => p)
        let disabled = true
        if (step === steps.selectBag && selectedProducts?.[0]) {
          disabled = false
        } else if (step === steps.selectProducts && currentSelectedProducts.length === 5) {
          disabled = false
        } else if (step === steps.reviewBag && currentSelectedProducts.length === 5) {
          disabled = false
        }
        return disabled
      }, [selectedProducts, step, steps.reviewBag, steps.selectBag, steps.selectProducts])

      const displayImages = useMemo(
        () => isExtraLarge || (!isExtraLarge && step === steps.selectProducts),
        [isExtraLarge, step, steps.selectProducts]
      )
      const displayNextButton = useMemo(
        () => step === steps.selectBag || (isMedium && step === steps.selectProducts),
        [isMedium, step, steps.selectBag, steps.selectProducts]
      )
      const displayNextIcon = useMemo(() => !isMedium && step === steps.selectProducts, [isMedium, step, steps.selectProducts])
      const displayBackButton = useMemo(
        () => step === steps.selectProducts || step === steps.reviewBag,
        [step, steps.reviewBag, steps.selectProducts]
      )
      const displayAddToCartButton = useMemo(() => step === steps.reviewBag, [step, steps.reviewBag])
      const displayTotal = useMemo(() => step === steps.reviewBag, [step, steps.reviewBag])

      const AddToCartTitle = useMemo(
        () => (!isSmall ? "Add to cart" : reviewId ? locales?.additionalUpdateCart : locales?.additionalAddToCart),
        [isSmall, locales?.additionalAddToCart, locales?.additionalUpdateCart, reviewId]
      )

      const placeholderItems = useMemo(() => {
        const startingNumber = selectedProducts.length
        return Array.from({ length: 5 - startingNumber }, (_, i) => i + startingNumber)
      }, [selectedProducts.length])

      const handleClickBack = useCallback(
        e => {
          e.preventDefault()
          handleGoBack()
        },
        [handleGoBack]
      )

      const handleClick = useCallback(() => {
        handleNext()
      }, [handleNext])

      const props = {
        locales,
        selectedProducts,
        buttonDisabled,
        displayImages,
        displayNextButton,
        displayNextIcon,
        displayBackButton,
        displayAddToCartButton,
        displayTotal,
        placeholderItems,
        AddToCartTitle,
        isMedium,
        reviewId,
        handleRemoveProduct,
        handleClick,
        handleClickBack,
      }

      Component.displayName = name
      return <Component {...(props as P)} />
    }
  )

type ExternalProps = Components.ComponentProps & {
  step: number
  steps: PickMix.Steps
  locales: PickMix.Locales
  selectedProducts: PickMix.SelectableProduct[]
  reviewId?: string
  handleRemoveProduct: (productHandle: string) => void
  handleGoBack: () => void
  handleNext: () => void
}

type InjectedProps = {
  locales: PickMix.Locales
  selectedProducts: PickMix.SelectableProduct[]
  buttonDisabled: boolean
  displayImages: boolean
  displayNextButton: boolean
  displayNextIcon: boolean
  displayBackButton: boolean
  displayAddToCartButton: boolean
  displayTotal: boolean
  placeholderItems: number[]
  AddToCartTitle: string
  isMedium: boolean
  reviewId?: string
  handleRemoveProduct: (productHandle: string) => void
  handleClick: () => void
  handleClickBack: () => void
}
