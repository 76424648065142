import React from "react"

import { withPickMixSelectFilter } from "./withPickMixSelectFilter"
import { ButtonWrapper, Button, FormWrapper } from "./styledPickMixSelectFilter"
import { FormSelect } from "../../../Form/Select/FormSelect"

export const PickMixSelectFilter = withPickMixSelectFilter(
  ({ items, activeIndex, isMedium, options, handleClick, handleChange }): JSX.Element =>
    isMedium ? (
      <ButtonWrapper>
        {items.map((item, index) => (
          <Button
            key={index}
            onClick={() => handleClick(item.productTag)}
            colour={activeIndex === index ? `orange` : `orange-outline`}
            size={`secondary`}
            title={item.title}
          >
            {item.title}
          </Button>
        ))}
      </ButtonWrapper>
    ) : (
      <FormWrapper>
        <FormSelect
          options={options}
          full
          onChange={handleChange}
          value={options[activeIndex]?.value}
          withSpacing={`xs`}
          required
          colour={"orange"}
        />
      </FormWrapper>
    )
)
