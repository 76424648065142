import React, { memo, useCallback, useMemo } from "react"

import { useApp } from "../../../../../hooks/useApp"
import { useTemplate } from "../../../../../hooks/useTemplate"

export const withProductBar = Component =>
  memo(({ name = "ProductBar", active, product, handleClickSelectProduct, handleClose }: any) => {
    const { headerBounds } = useApp()
    const { product: locales } = useTemplate()

    const handleClick = useCallback(() => {
      handleClickSelectProduct(product)
      handleClose()
    }, [handleClickSelectProduct, handleClose, product])

    Component.displayName = name
    return useMemo(
      () => <Component active={active} bounds={headerBounds} handleClick={handleClick} locales={locales} product={product} />,
      [active, headerBounds, handleClick, locales, product]
    )
  })
