import tw, { css, styled } from "twin.macro"

import { StyledLink } from "../../../Styled/Button"
import { StyledColumn } from "../../../Styled/Column"
import { StyledContainer } from "../../../Styled/Container"
import { StyledRow } from "../../../Styled/Row"
import { GlobalStyles } from "../../../Styled/Global"
import { Overline, RichText } from "../../../Styled/Text"

export const Overlay = styled.div`
  ${tw`hidden md:block fixed z-20 bg-white bg-opacity-70 inset-0 top-36 sm:top-32 lg:top-33 cursor-pointer invisible opacity-0 transition-all duration-200`}
  ${({ active }) => active && tw`visible opacity-100`}
`
export const Drawer = styled.div`
  ${tw`fixed flex flex-col z-20 md:w-158 inset-0 top-36 sm:top-32 lg:top-33 bg-white overflow-x-hidden overflow-y-scroll transform -translate-x-full invisible transition-all duration-200`}
  ${({ active }) => active && tw`translate-x-0 visible`}
  ${({ top }) =>
    top &&
    css`
      @media (max-width: 767px) {
        top: ${top}px;
      }
    `}
`
export const DrawerInner = tw.div`relative`
export const Close = tw(StyledLink)`sticky flex w-full justify-end z-10 top-0 -mt-10 pt-4 pr-4`
export const Section = tw.section`relative`
export const Column = styled(StyledColumn)`
  ${({ colour }) => colour && GlobalStyles.background[colour]}
  ${({ withScroll }) => withScroll && tw`py-5.25`}
  ${({ withScroll }) =>
    withScroll &&
    css`
      &::-webkit-scrollbar {
        ${tw`w-1`}
      }
      &::-webkit-scrollbar-thumb {
        ${tw`bg-grey-light rounded-3xl`}
      }
    `}
`
export const Content = styled.div`
  ${({ colour }) => colour && GlobalStyles.background[colour]}
`
export const Container = tw(StyledContainer)`h-full`
export const Row = tw(StyledRow)`h-full`
export const Details = tw.div`w-full`
export const DescriptionRow = tw(StyledRow)`lg:justify-start`
export const Description = tw(Overline)`text-base`
export const DescriptionContent = tw(RichText)`pr-2 lg:pr-0`
